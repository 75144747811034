import axios from 'axios';
const path ='http://example.com/'

export  const fetchAllCourses = () => (
    axios({
        method: "GET",
        url: `${path}api/courses`
    })
)

export const fetchCourse = courseId => (
    axios({
        method: "GET",
        url: `${path}api/courses/${courseId}`
    })
)