import axios from 'axios';
const path ='http://example.com/'

export const login = user => (
    axios({
        method: 'POST',
        url: `${path}/api/session`,
        data: { user }
    })
);

export const signup = user => (
    axios({
        method: 'POST',
        url: `${path}/api/users`,
        data: { user }
    })
);

export const logout = () => (
    axios({
        method: 'DELETE',
        url: '/api/session'
    })
);


