import axios from 'axios';
const path ='http://example.com/'

export const fetchAllVideos = (courseId, courseContentId) => (
    axios({
        method: "GET",
        url: `${path}/api/courses/${courseId}/course_contents/${courseContentId}/videos`
    })
)

export const fetchVideo = (courseId, courseContentId,videoId) => (
    axios({
        method: "GET",
        url: `${path}/api/courses/${courseId}/course_contents/${courseContentId}/videos/${videoId}`
    })
)